@use '~/src/lib/scss/index' as u;

.homepage-banner {
	display: flex;
	flex-direction: column;

	@include u.medium-desktop {
		flex-direction: row-reverse;
		justify-content: space-between;
		gap: 16px;

		> div {
			max-width: 50%;
		}
	}

	.hero-image-container {
		aspect-ratio: 16/9;
		width: 100%;
		text-align: center;

		.Picture,
		img {
			aspect-ratio: 16/9;
			width: 100%;
			object-fit: cover;
		}

		video {
			aspect-ratio: 16/9;
			width: 100%;
		}

		// Create a gradient effect over the left edge of the image
		// @include u.medium-desktop {
		// 	display: grid;
		// 	.hero-image {
		// 		grid-area: 1 / 1;
		// 	}
		// }
	}

	.title-container {
		display: grid;
		grid-template-columns: 1fr;
		justify-items: center;
		gap: 12px;
		padding: 24px 16px 0;

		@include u.medium-desktop {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 24px;
			padding: 24px 16px;
		}

		.headline {
			text-align: center;

			@include u.large-desktop {
				text-align: unset;
			}
		}
		.subtext {
			font-size: 14px;
			line-height: 20px;
			text-align: center;

			@include u.medium-desktop {
				text-align: unset;
			}

			@include u.large-desktop {
				font-size: 20px;
				line-height: 26px;
			}
		}

		.streaming-locations {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			margin: 24px 0;

			@include u.medium-desktop {
				display: none;
			}

			.free-on {
				font-size: 12px;
				text-transform: uppercase;
			}

			.streaming-icons {
				display: flex;
				flex-direction: row;

				.icon-container {
					border-right: 1px solid #555555;
					padding: 0 16px;

					&:last-child {
						border: 0;
					}

					> img {
						width: 32px;
					}

					svg {
						display: block;
						aspect-ratio: 1/1;
						width: 34px;
						height: 100%;
					}
				}
			}
		}

		.buttons {
			display: grid;
			grid-template-columns: 1fr;
			justify-items: center;
			gap: 16px;
			padding: 0 32px;
			width: 100%;

			@include u.medium-desktop {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				padding: 16px 0 0 0;
			}

			.cta {
				padding: 10px;
				width: 100%;
				max-width: 295px;
				line-height: 1;
			}
		}
	}
}
