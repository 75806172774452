@use '~/src/lib/scss/index' as u;

.homepage-countdown {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid #000;
	background-color: var(--color-otx-blue);
	padding: 8px 0px;
	width: 100%;
	max-width: 1440px;

	@include u.medium-desktop {
		padding: 16px 0px;
	}

	@include u.medium-desktop {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		padding: 16px 40px;
	}

	.date {
		display: none;

		@include u.medium-desktop {
			display: flex;
			font-weight: 900;
			font-size: 16px;
			line-height: 20px; /* 125% */
			font-family: Orbitron;
			text-transform: uppercase;
		}
	}

	.main {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;

		@include u.medium-desktop {
			gap: 24px;
		}

		.logo {
			border-right: 1px solid var(--color-blue-45);
			padding-right: 16px;

			@include u.medium-desktop {
				padding-right: 24px;
			}

			img {
				height: 24px;

				@include u.medium-desktop {
					height: 32px;
				}
			}
		}

		.live {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 16px;

			.indicator {
				transform: scale(1);
				animation: pulse-white 2s infinite;
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
				border-radius: 50%;
				background-color: rgb(255, 255, 255);
				width: 8px;
				height: 8px;

				@include u.medium-desktop {
					width: 10.25px;
					height: 10.25px;
				}

				@keyframes pulse-white {
					0% {
						transform: scale(0.95);
						box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
					}

					70% {
						transform: scale(1);
						box-shadow: 0 0 0 6px rgba(255, 255, 255, 0);
					}

					100% {
						transform: scale(0.95);
						box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
					}
				}
			}

			.text {
				font-weight: 900;
				font-size: 14px;
				line-height: 20px;
				font-family: Orbitron;
				text-transform: uppercase;

				@include u.medium-desktop {
					font-size: 20px;
					line-height: 24px;
				}
			}
		}

		.timer {
			display: grid;
			grid-template-columns: repeat(7, auto);
			justify-items: center;

			.digits {
				width: 28px;
				font-weight: 900;
				font-size: 14px;
				line-height: 20px;
				font-family: Orbitron;
				text-align: center;
				text-transform: uppercase;

				@include u.medium-desktop {
					width: 40px;
					font-size: 20px;
					line-height: 24px;
				}
			}

			.separator {
				width: 10px;
				text-align: center;
			}

			.labels {
				color: #fff;
				font-style: normal;
				font-weight: 500;
				font-size: 8px;
				line-height: 12px;
				text-transform: uppercase;

				@include u.medium-desktop {
					font-size: 12px;
					line-height: 18px;
				}
			}
		}
	}

	.streaming-locations {
		display: none;

		@include u.medium-desktop {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-self: flex-end;
			gap: 8px;
		}

		.free-on {
			font-size: 14px;
			text-transform: uppercase;
		}

		.streaming-icons {
			display: flex;
			flex-direction: row;

			.icon-container {
				border-right: 1px solid var(--color-blue-45);
				padding: 0 16px;

				&:last-child {
					border: 0;
				}

				> img {
					width: 32px;
				}

				svg {
					display: block;
					aspect-ratio: 1/1;
					width: 34px;
					height: 100%;
				}
			}
		}
	}
}
