@use '~/src/lib/scss/index' as u;

.homepage-page {
	display: flex;
	flex-direction: column;
	align-items: center;

	> .content-container {
		display: flex;
		flex-direction: column;
		gap: 32px;
		padding: 0;

		@include u.medium-desktop {
			gap: 56px;
			padding: 0 32px;
		}

		@include u.full-desktop {
			padding: 0;
		}
	}

	.section-heading {
		padding-right: var(--size-gap);
		padding-left: var(--size-gap);

		@include u.medium-desktop {
			padding-left: unset;
		}
	}
}
