@use '~/src/lib/scss/index' as u;

.homepage-videos {
	.list-container {
		display: grid;

		.list {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			justify-items: center;
			gap: 10px;
			padding: 0 var(--size-gap);
			overflow-x: auto;
			scroll-behavior: smooth;
			scrollbar-width: none;

			@include u.medium-desktop {
				padding: 0;
			}

			.video {
				display: flex;
				flex-shrink: 0;
				flex-direction: column;
				border-radius: 16px;
				background: linear-gradient(180deg, #1d1c1c 0%, #161616 100%);
				width: 320px;

				&--short {
					width: 220px;
				}

				&:hover {
					opacity: 1;
					color: var(--color-white);

					.thumbnail {
						opacity: 0.75;
					}
				}

				.thumbnail-container {
					display: grid;
					align-items: center;
					justify-items: center;
					padding: 8px 8px 0;
					aspect-ratio: 9/14;

					.thumbnail {
						grid-area: 1 / 1 / 1 / 1;
						transition: 0.33s ease all;
						border-radius: 10px;
						height: 170px;
						object-fit: cover;

						&--short {
							width: 100%;
							height: 364px;
						}
					}
				}

				.title-container {
					padding: 16px;

					.title {
						font-size: 14px;
						@include u.max-lines(3);
					}
				}
			}
		}
	}
}
