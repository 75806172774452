@use '~/src/lib/scss/index' as u;

.homepage-partnerships {
	margin-top: var(--size-gap-2x);
	background-color: var(--color-gray-90);
	width: 100%;

	.content-container {
		justify-items: center;
		padding: var(--size-gap-3x) 0;

		.title {
			padding: 2px 0;
			font-size: 20px;
		}

		.list-container {
			display: flex;
			flex-direction: row;
			justify-content: center;

			.list {
				display: flex;
				flex-basis: 600px;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				gap: 40px;
				margin-top: 32px;

				@media (min-width: 1000px) {
					flex-basis: unset;
				}

				div {
					display: flex;
					justify-content: center;
					width: 145px;
				}

				a {
					padding: 0 16px;
				}

				.c4 {
					height: 32px;
				}

				.dazn {
					height: 40px;
				}

				.theGeneral {
					height: 32px;
				}
			}
		}
	}
}
