:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.homepage-page {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.homepage-page > .content-container {
  flex-direction: column;
  gap: 32px;
  padding: 0;
  display: flex;
}

@media (width >= 1024px) {
  .homepage-page > .content-container {
    gap: 56px;
    padding: 0 32px;
  }
}

@media (width >= 1456px) {
  .homepage-page > .content-container {
    padding: 0;
  }
}

.homepage-page .section-heading {
  padding-right: var(--size-gap);
  padding-left: var(--size-gap);
}

@media (width >= 1024px) {
  .homepage-page .section-heading {
    padding-left: unset;
  }
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.homepage-banner {
  flex-direction: column;
  display: flex;
}

@media (width >= 1024px) {
  .homepage-banner {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 16px;
  }

  .homepage-banner > div {
    max-width: 50%;
  }
}

.homepage-banner .hero-image-container {
  aspect-ratio: 16 / 9;
  text-align: center;
  width: 100%;
}

.homepage-banner .hero-image-container .Picture, .homepage-banner .hero-image-container img {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  width: 100%;
}

.homepage-banner .hero-image-container video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.homepage-banner .title-container {
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 12px;
  padding: 24px 16px 0;
  display: grid;
}

@media (width >= 1024px) {
  .homepage-banner .title-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    padding: 24px 16px;
    display: flex;
  }
}

.homepage-banner .title-container .headline {
  text-align: center;
}

@media (width >= 1200px) {
  .homepage-banner .title-container .headline {
    text-align: unset;
  }
}

.homepage-banner .title-container .subtext {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}

@media (width >= 1024px) {
  .homepage-banner .title-container .subtext {
    text-align: unset;
  }
}

@media (width >= 1200px) {
  .homepage-banner .title-container .subtext {
    font-size: 20px;
    line-height: 26px;
  }
}

.homepage-banner .title-container .streaming-locations {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 24px 0;
  display: flex;
}

@media (width >= 1024px) {
  .homepage-banner .title-container .streaming-locations {
    display: none;
  }
}

.homepage-banner .title-container .streaming-locations .free-on {
  text-transform: uppercase;
  font-size: 12px;
}

.homepage-banner .title-container .streaming-locations .streaming-icons {
  flex-direction: row;
  display: flex;
}

.homepage-banner .title-container .streaming-locations .streaming-icons .icon-container {
  border-right: 1px solid #555;
  padding: 0 16px;
}

.homepage-banner .title-container .streaming-locations .streaming-icons .icon-container:last-child {
  border: 0;
}

.homepage-banner .title-container .streaming-locations .streaming-icons .icon-container > img {
  width: 32px;
}

.homepage-banner .title-container .streaming-locations .streaming-icons .icon-container svg {
  aspect-ratio: 1;
  width: 34px;
  height: 100%;
  display: block;
}

.homepage-banner .title-container .buttons {
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 16px;
  width: 100%;
  padding: 0 32px;
  display: grid;
}

@media (width >= 1024px) {
  .homepage-banner .title-container .buttons {
    flex-direction: row;
    justify-content: flex-start;
    padding: 16px 0 0;
    display: flex;
  }
}

.homepage-banner .title-container .buttons .cta {
  width: 100%;
  max-width: 295px;
  padding: 10px;
  line-height: 1;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.homepage-countdown {
  background-color: var(--color-otx-blue);
  border-bottom: 1px solid #000;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  padding: 8px 0;
  display: flex;
}

@media (width >= 1024px) {
  .homepage-countdown {
    grid-template-columns: 1fr auto 1fr;
    padding: 16px 40px;
    display: grid;
  }
}

.homepage-countdown .date {
  display: none;
}

@media (width >= 1024px) {
  .homepage-countdown .date {
    text-transform: uppercase;
    font-family: Orbitron;
    font-size: 16px;
    font-weight: 900;
    line-height: 20px;
    display: flex;
  }
}

.homepage-countdown .main {
  flex-direction: row;
  align-items: center;
  gap: 16px;
  display: flex;
}

@media (width >= 1024px) {
  .homepage-countdown .main {
    gap: 24px;
  }
}

.homepage-countdown .main .logo {
  border-right: 1px solid var(--color-blue-45);
  padding-right: 16px;
}

@media (width >= 1024px) {
  .homepage-countdown .main .logo {
    padding-right: 24px;
  }
}

.homepage-countdown .main .logo img {
  height: 24px;
}

@media (width >= 1024px) {
  .homepage-countdown .main .logo img {
    height: 32px;
  }
}

.homepage-countdown .main .live {
  flex-direction: row;
  align-items: center;
  gap: 16px;
  display: flex;
}

.homepage-countdown .main .live .indicator {
  background-color: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation: 2s infinite pulse-white;
  transform: scale(1);
  box-shadow: 0 0 #000;
}

@media (width >= 1024px) {
  .homepage-countdown .main .live .indicator {
    width: 10.25px;
    height: 10.25px;
  }
}

@keyframes pulse-white {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #ffffffb3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px #fff0;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #fff0;
  }
}

.homepage-countdown .main .live .text {
  text-transform: uppercase;
  font-family: Orbitron;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
}

@media (width >= 1024px) {
  .homepage-countdown .main .live .text {
    font-size: 20px;
    line-height: 24px;
  }
}

.homepage-countdown .main .timer {
  grid-template-columns: repeat(7, auto);
  justify-items: center;
  display: grid;
}

.homepage-countdown .main .timer .digits {
  text-align: center;
  text-transform: uppercase;
  width: 28px;
  font-family: Orbitron;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
}

@media (width >= 1024px) {
  .homepage-countdown .main .timer .digits {
    width: 40px;
    font-size: 20px;
    line-height: 24px;
  }
}

.homepage-countdown .main .timer .separator {
  text-align: center;
  width: 10px;
}

.homepage-countdown .main .timer .labels {
  color: #fff;
  text-transform: uppercase;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

@media (width >= 1024px) {
  .homepage-countdown .main .timer .labels {
    font-size: 12px;
    line-height: 18px;
  }
}

.homepage-countdown .streaming-locations {
  display: none;
}

@media (width >= 1024px) {
  .homepage-countdown .streaming-locations {
    flex-direction: row;
    justify-self: flex-end;
    align-items: center;
    gap: 8px;
    display: flex;
  }
}

.homepage-countdown .streaming-locations .free-on {
  text-transform: uppercase;
  font-size: 14px;
}

.homepage-countdown .streaming-locations .streaming-icons {
  flex-direction: row;
  display: flex;
}

.homepage-countdown .streaming-locations .streaming-icons .icon-container {
  border-right: 1px solid var(--color-blue-45);
  padding: 0 16px;
}

.homepage-countdown .streaming-locations .streaming-icons .icon-container:last-child {
  border: 0;
}

.homepage-countdown .streaming-locations .streaming-icons .icon-container > img {
  width: 32px;
}

.homepage-countdown .streaming-locations .streaming-icons .icon-container svg {
  aspect-ratio: 1;
  width: 34px;
  height: 100%;
  display: block;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.homepage-events .list-container {
  display: grid;
}

.homepage-events .list-container .list {
  padding: 0 var(--size-gap);
  scroll-behavior: smooth;
  scrollbar-width: none;
  flex-direction: row;
  gap: 24px;
  display: flex;
  overflow-x: auto;
}

@media (width >= 1024px) {
  .homepage-events .list-container .list {
    padding: 0;
  }
}

.homepage-events .list-container .list a {
  opacity: 1;
  color: var(--color-white);
}

.homepage-events .list-container .list a .info {
  transition: all .33s;
}

.homepage-events .list-container .list a:hover .info {
  opacity: 1;
}

.homepage-events .list-container .list .event {
  background: linear-gradient(0deg, #1d1c1c 0%, #161616 48.56%);
  border-radius: 12px;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto;
  align-content: flex-start;
  min-width: 300px;
  display: grid;
  overflow: hidden;
}

@media (width >= 768px) {
  .homepage-events .list-container .list .event {
    max-width: 342px;
  }
}

.homepage-events .list-container .list .event .number-container {
  z-index: 2;
  flex-direction: row;
  grid-area: 1 / 1;
  display: flex;
}

.homepage-events .list-container .list .event .number-container .number {
  background-color: var(--color-otx-blue);
  border-radius: 12px 0 0;
  justify-self: center;
  height: 48px;
  padding: 12px;
}

.homepage-events .list-container .list .event .number-container .number--past {
  background-color: var(--color-gray-80);
}

.homepage-events .list-container .list .event .thumbnail {
  aspect-ratio: 4 / 3;
  grid-area: 1 / 1;
  display: grid;
}

.homepage-events .list-container .list .event .thumbnail .Picture {
  grid-area: 1 / 1;
}

.homepage-events .list-container .list .event .thumbnail .Picture img {
  border-radius: 12px 12px 0 0;
  width: 100%;
}

.homepage-events .list-container .list .event .thumbnail .sold-out {
  z-index: 1;
  text-transform: uppercase;
  background-color: #000000bf;
  grid-area: 1 / 1;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  display: flex;
}

.homepage-events .list-container .list .event .info {
  grid-area: 2 / 1;
  min-height: 120px;
  padding: 16px;
}

.homepage-events .list-container .list .event .info .fight-name {
  font-size: 16px;
}

.homepage-events .list-container .list .event .info .fighters {
  text-transform: uppercase;
  padding: 8px 0;
  font-size: 20px;
}

.homepage-events .list-container .list .event .info .fighters .fighter-names-container {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.homepage-events .list-container .list .event .info .fighters .fighter-names-container .versus {
  padding: 0 var(--size-gap-half);
  font-size: .6em;
}

.homepage-events .list-container .list .event .info .tournament-info {
  padding: 0 0 var(--size-gap-half);
  color: var(--color-blue-25);
  text-transform: uppercase;
  font-size: 14px;
}

.homepage-events .list-container .list .event .info .location {
  text-transform: uppercase;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  display: flex;
}

.homepage-events .list-container .list .event .info .location img {
  height: 18px;
  padding-right: 4px;
}

.homepage-events .list-container .list .event .buttons {
  flex-direction: row;
  grid-area: 3 / 1;
  gap: 12px;
  padding: 16px;
  display: flex;
}

.homepage-events .list-container .list .event .buttons > .coming-soon {
  border: 1px solid var(--color-gray-30);
  color: var(--color-gray-30);
  background-color: #0000;
}

.homepage-events .list-container .list .event .buttons .primary {
  flex-grow: 1;
}

.homepage-events .list-container .list .event .buttons .secondary {
  flex-grow: 1;
  min-width: 48%;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.homepage-partnerships {
  margin-top: var(--size-gap-2x);
  background-color: var(--color-gray-90);
  width: 100%;
}

.homepage-partnerships .content-container {
  padding: var(--size-gap-3x) 0;
  justify-items: center;
}

.homepage-partnerships .content-container .title {
  padding: 2px 0;
  font-size: 20px;
}

.homepage-partnerships .content-container .list-container {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.homepage-partnerships .content-container .list-container .list {
  flex-flow: wrap;
  flex-basis: 600px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 32px;
  display: flex;
}

@media (width >= 1000px) {
  .homepage-partnerships .content-container .list-container .list {
    flex-basis: unset;
  }
}

.homepage-partnerships .content-container .list-container .list div {
  justify-content: center;
  width: 145px;
  display: flex;
}

.homepage-partnerships .content-container .list-container .list a {
  padding: 0 16px;
}

.homepage-partnerships .content-container .list-container .list .c4 {
  height: 32px;
}

.homepage-partnerships .content-container .list-container .list .dazn {
  height: 40px;
}

.homepage-partnerships .content-container .list-container .list .theGeneral {
  height: 32px;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.homepage-videos .list-container {
  display: grid;
}

.homepage-videos .list-container .list {
  padding: 0 var(--size-gap);
  scroll-behavior: smooth;
  scrollbar-width: none;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  gap: 10px;
  display: grid;
  overflow-x: auto;
}

@media (width >= 1024px) {
  .homepage-videos .list-container .list {
    padding: 0;
  }
}

.homepage-videos .list-container .list .video {
  background: linear-gradient(#1d1c1c 0%, #161616 100%);
  border-radius: 16px;
  flex-direction: column;
  flex-shrink: 0;
  width: 320px;
  display: flex;
}

.homepage-videos .list-container .list .video--short {
  width: 220px;
}

.homepage-videos .list-container .list .video:hover {
  opacity: 1;
  color: var(--color-white);
}

.homepage-videos .list-container .list .video:hover .thumbnail {
  opacity: .75;
}

.homepage-videos .list-container .list .video .thumbnail-container {
  aspect-ratio: 9 / 14;
  place-items: center;
  padding: 8px 8px 0;
  display: grid;
}

.homepage-videos .list-container .list .video .thumbnail-container .thumbnail {
  object-fit: cover;
  border-radius: 10px;
  grid-area: 1 / 1 / 1 / 1;
  height: 170px;
  transition: all .33s;
}

.homepage-videos .list-container .list .video .thumbnail-container .thumbnail--short {
  width: 100%;
  height: 364px;
}

.homepage-videos .list-container .list .video .title-container {
  padding: 16px;
}

.homepage-videos .list-container .list .video .title-container .title {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
}

/*# sourceMappingURL=Homepage.7370e48a.css.map */
