@use '~/src/lib/scss/index' as u;

.homepage-events {
	.list-container {
		display: grid;

		.list {
			display: flex;
			flex-direction: row;
			gap: 24px;
			padding: 0 var(--size-gap);
			overflow-x: auto;
			scroll-behavior: smooth;
			scrollbar-width: none;

			@include u.medium-desktop {
				padding: 0;
			}

			a {
				opacity: 1;
				color: var(--color-white);

				.info {
					transition: 0.33s ease all;
				}

				&:hover .info {
					opacity: 1;
				}
			}

			.event {
				display: grid;
				grid-template-rows: auto 1fr auto;
				grid-template-columns: auto;
				align-content: flex-start;
				border-radius: 12px;
				background: linear-gradient(0deg, #1d1c1c 0%, #161616 48.56%);
				min-width: 300px;
				overflow: hidden;

				@include u.desktop {
					max-width: 342px;
				}

				.number-container {
					display: flex;
					grid-area: 1 / 1;
					flex-direction: row;
					z-index: 2;

					.number {
						justify-self: center;
						border-radius: 12px 0 0 0;
						background-color: var(--color-otx-blue);
						padding: 12px;
						height: 48px;

						&--past {
							background-color: var(--color-gray-80);
						}
					}
				}

				.thumbnail {
					display: grid;
					grid-area: 1 / 1;
					aspect-ratio: 4/3;

					.Picture {
						grid-area: 1 / 1;

						img {
							border-radius: 12px 12px 0 0;
							width: 100%;
						}
					}

					.sold-out {
						display: flex;
						grid-area: 1 / 1;
						justify-content: center;
						align-items: center;
						z-index: 1;
						background-color: rgba(0, 0, 0, 0.75);
						font-weight: 700;
						font-size: 18px;
						text-transform: uppercase;
					}
				}

				.info {
					grid-area: 2 / 1;
					padding: 16px;
					min-height: 120px;

					.fight-name {
						font-size: 16px;
					}

					.fighters {
						padding: 8px 0 8px;
						font-size: 20px;
						text-transform: uppercase;

						.fighter-names-container {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							align-items: center;

							.versus {
								padding: 0 var(--size-gap-half);
								font-size: 0.6em;
							}
						}
					}

					.tournament-info {
						padding: 0 0 var(--size-gap-half);
						color: var(--color-blue-25);
						font-size: 14px;
						text-transform: uppercase;
					}

					.location {
						display: flex;
						flex-direction: row;
						align-items: center;
						font-size: 16px;
						text-transform: uppercase;

						img {
							padding-right: 4px;
							height: 18px;
						}
					}
				}

				.buttons {
					display: flex;
					grid-area: 3 / 1;
					flex-direction: row;
					gap: 12px;
					padding: 16px;

					> .active {
					}
					> .sold-out {
					}
					> .coming-soon {
						border: 1px solid var(--color-gray-30);
						background-color: transparent;
						color: var(--color-gray-30);
					}

					.primary {
						flex-grow: 1;
					}

					.secondary {
						flex-grow: 1;
						min-width: 48%;
					}
				}
			}
		}
	}
}
